import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity } from 'app/entities/community-announcement/community-announcement.reducer';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';

export const CommunityAnnouncementDeleteDialog = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentPrayerWalkEvent = useCurrentEvent();
  const { id } = useParams<'id'>();

  const handleClose = () => {
    navigate('/announcements');
  };

  const confirmDelete = () => {
    if (currentPrayerWalkEvent && id) {
      dispatch(deleteEntity({ id: parseInt(id, 10), prayerWalkEventId: currentPrayerWalkEvent.id })).then(() => {
        handleClose();
      });
    }
  };

  return (
    <Modal isOpen={true} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm delete operation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to delete this Community Announcement?</ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={confirmDelete}>
            Delete
          </Button>
          <Button variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CommunityAnnouncementDeleteDialog;
