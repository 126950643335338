import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';
import { IUser } from 'app/shared/model/user.model';

export interface ICommunityAnnouncement {
  id?: number;
  title?: string | null;
  body?: string | null;
  prayerWalkEvent?: IPrayerWalkEvent | null;
  author?: IUser | null;
  isRead?: boolean | null;
  createdDate?: string | null;
}

export const defaultValue: Readonly<ICommunityAnnouncement> = {};
