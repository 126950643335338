import { IUserProfile } from 'app/shared/model/user-profile.model';
import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';

export interface IChurch {
  id?: number;
  name?: string | null;
  address?: string | null;
  googlePlaceId?: string | null;
  userProfiles?: IUserProfile[] | null;
  prayerWalkEvents?: IPrayerWalkEvent[] | null;
}

export const defaultValue: Readonly<IChurch> = {};
