import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';

export interface IPrayerEventChallenge {
  id?: number;
  title?: string | null;
  icon?: string | null;
  prayerWalkEvent?: IPrayerWalkEvent | null;

  completed?: boolean;
}

export const defaultValue: Readonly<IPrayerEventChallenge> = {};
