import React, { useEffect, PropsWithChildren } from 'react';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Navigate } from 'react-router-dom';
import { defaultValue } from 'app/shared/model/user-profile.model';
import { getEntityForCurrentUser, reset } from 'app/entities/user-profile/user-profile.reducer';

// Is this wrapping the component stack inefficient? It only needs to be checked once
const AccountChecks = (props: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.userProfile.loading);
  const userProfile = useAppSelector(state => state.userProfile.entity);
  const { children } = props;

  useEffect(() => {
    dispatch(getEntityForCurrentUser());
    return () => {
      dispatch(reset());
    };
  }, []);

  if (userProfile !== defaultValue && userProfile !== '' && userProfile.church && !userProfile.verifiedChurch) {
    return <Navigate to="/account/confirm-church" />;
  }

  if (!loading && userProfile !== defaultValue && userProfile !== '' && (userProfile.verifiedChurch || !userProfile.church)) {
    // allow user to continue
    return <>{children}</>;
  } else {
    if (loading) {
      return <LoadingSpinner />;
    } else {
      return <>{children}</>;
    }
  }
};

export default AccountChecks;
