import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { ChurchOption } from 'app/shared/components/church-selector';

export interface IUseSearchChurchesReturn {
  churches: ChurchOption[];
  isLoading: boolean;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  searchChurches: (string) => Promise<ChurchOption[]>;
}

export const useSearchChurches: () => IUseSearchChurchesReturn = () => {
  const [churches, setChurches] = useState<ChurchOption[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    searchChurches(searchTerm).then(options => setChurches(options));
  }, [searchTerm]);

  const searchChurches = useCallback(query => {
    setLoading(true);
    return axios
      .get('/api/searchable-churches', {
        params: { q: query },
      })
      .then(res => {
        const options = res.data.churches.map(church => ({
          value: church.googlePlaceId,
          label: church.name,
          address: church.address,
        }));
        setLoading(false);
        return options;
      });
  }, []);

  return { churches, isLoading, setSearchTerm, searchChurches };
};
