import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getDashboardStats } from 'app/modules/organisation/main/prayer-walk-event-stats.reducer';
import { Link, useParams } from 'react-router-dom';
import { Box, Button, Flex, Heading, SimpleGrid, VStack, Spacer, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { Stat } from 'app/modules/participants/stats/stat-bar';
import { IPrayerWalkEventDashboardStats } from 'app/shared/model/prayer-walk-event-dashboard-stats';
import { getEntity } from 'app/entities/prayer-walk-event/prayer-walk-event.reducer';
import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';
import { PrayerWalkEventHeadline } from 'app/modules/organisation/main/prayer-walk-events';
import { DownloadIcon, EditIcon } from '@chakra-ui/icons';
import { getAuthToken } from 'app/config/axios-interceptor';
import { useOrganisationHasCancelledSubscription } from 'app/shared/hooks/useUsersOrganisation';
import { ButtonLink } from 'app/shared/components/button-link';
import { MailingList, MailingListHeaderAndTable } from './prayer-walk-event-overview/mailing-list';
import { PrayerNeedsHeaderAndTable } from 'app/modules/organisation/main/prayer-walk-event-overview/prayer-needs-table';
import { getPrayerEventChallengeStats } from 'app/modules/organisation/main/prayer-event-challenge-stats.reducer';
import { IPrayerEventChallengeStats } from 'app/shared/model/prayer-event-challenge-stats';
import { PrayerEventChallengeStats } from 'app/modules/organisation/main/prayer-walk-event-overview/prayer-event-challenge-stats';
import { UserEngagementHeaderAndTable } from 'app/modules/organisation/main/prayer-walk-event-overview/user-engagement';
import { distanceFormat } from 'app/shared/util/distance-format';
import { PrayerChampions } from './prayer-walk-event-overview/prayer-champions';

export const OrganisationPrayerWalkEventOverview = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const prayerWalkEvent: IPrayerWalkEvent = useAppSelector(state => state.prayerWalkEvent.entity);
  const stats: IPrayerWalkEventDashboardStats = useAppSelector(state => state.prayerWalkEventDashboardStats.entity);
  const prayerEventChallengeStats: IPrayerEventChallengeStats[] = useAppSelector(state => state.prayerEventChallengeStats.stats);
  const loading: boolean = useAppSelector(state => state.prayerWalkEventDashboardStats.loading);
  const inactiveSubscription = useOrganisationHasCancelledSubscription();

  useEffect(() => {
    dispatch(getDashboardStats(id));
  }, []);

  useEffect(() => {
    dispatch(getPrayerEventChallengeStats(id));
  }, []);

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  return (
    <VStack spacing={4} w="full" h="full">
      <Flex w="full">
        <PrayerWalkEventHeadline prayerWalkEvent={prayerWalkEvent} />
        <ButtonLink isDisabled={inactiveSubscription} to="edit" rightIcon={<EditIcon />} id="editEvent">
          Edit event
        </ButtonLink>
      </Flex>
      <Box bg="white" p={4} w="full">
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4, lg: 6 }} spacing={8}>
          <Stat value={stats && stats.numberOfParticipants} label="Number of participants" isLoading={loading} />
          <Stat value={stats && stats.numberOfUsersWithAPrayerWalk} label="Number of users with a prayer walk" isLoading={loading} />
          <Stat value={stats && stats.numberOfUsersWithoutAPrayerWalk} label="Number of users without a prayer walk" isLoading={loading} />
          <Stat
            value={stats && stats.numberOfUsersWithAnEarmarkedPrayerWalk}
            label="Number of users with an earmarked prayer walk"
            isLoading={loading}
          />
          <Stat
            value={stats && stats.numberOfUsersWithACompletedPrayerWalk}
            label="Number of users with a completed prayer walk"
            isLoading={loading}
          />
          <Stat value={stats && stats.numberOfPrayerWalks} label="Number of prayer walks" isLoading={loading} />
          <Stat value={stats && stats.numberOfCompletedPrayerWalks} label="Number of completed prayer walks" isLoading={loading} />
          <Stat value={stats && stats.numberOfEarmarkedPrayerWalks} label="Number of earmarked prayer walks" isLoading={loading} />
          <Stat
            value={distanceFormat({ distanceInKm: stats && stats.lengthOfAllCompletedPrayerWalks })}
            label="Length of all completed prayer walks"
            isLoading={loading}
          />
          <Stat
            value={distanceFormat({ distanceInKm: stats && stats.lengthOfAllEarmarkedPrayerWalks })}
            label="Length of all earmarked prayer walks"
            isLoading={loading}
          />
          <Stat value={stats && stats.numberOfPrayerNeeds} label="Number of prayer needs" isLoading={loading} />
        </SimpleGrid>
      </Box>
      <Tabs w="full">
        <TabList>
          <Tab>Mailing List</Tab>
          <Tab>Prayer Needs</Tab>
          <Tab>User Engagement</Tab>
          <Tab>Prayer Champions</Tab>
          {prayerWalkEvent &&
            prayerWalkEvent.settings &&
            prayerWalkEvent.settings.features &&
            prayerWalkEvent.settings.features.prayerEventChallengesEnabled && <Tab>Prayer Steps</Tab>}
        </TabList>

        <TabPanels>
          <TabPanel p={0}>
            <MailingListHeaderAndTable prayerWalkEvent={prayerWalkEvent} inactiveSubscription={inactiveSubscription} />
          </TabPanel>
          <TabPanel p={0}>
            <PrayerNeedsHeaderAndTable prayerWalkEvent={prayerWalkEvent} inactiveSubscription={inactiveSubscription} />
          </TabPanel>
          <TabPanel p={0}>
            <UserEngagementHeaderAndTable prayerWalkEvent={prayerWalkEvent} inactiveSubscription={inactiveSubscription} />
          </TabPanel>
          <TabPanel p={0}>
            <PrayerChampions prayerWalkEvent={prayerWalkEvent} inactiveSubscription={inactiveSubscription} />
          </TabPanel>
          <TabPanel p={0}>
            <PrayerEventChallengeStats prayerEventChallengeStats={prayerEventChallengeStats} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};
