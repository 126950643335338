import React, { useState, useEffect } from 'react';

import { Navigate } from 'react-router-dom';

import { getEntityForCurrentUser, reset, updateEntity } from 'app/entities/user-profile/user-profile.reducer';
import { defaultValue, IUserProfile } from 'app/shared/model/user-profile.model';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { hideNav, showNav } from 'app/shared/reducers/app-layout';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useForm } from 'react-hook-form';
import { Button, Box, Flex, VStack, HStack, chakra } from '@chakra-ui/react';
import { ChurchOption, ChurchSelector } from 'app/shared/components/church-selector';
import { useSearchChurches } from 'app/shared/hooks/useSearchChurches';

interface FormValues {
  verifiedChurchGooglePlaceId: ChurchOption;
}

export const FinishChurchSelection = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.userProfile.loading);
  const userProfile = useAppSelector(state => state.userProfile.entity);
  const { churches, isLoading, setSearchTerm } = useSearchChurches();
  const [predictedChurchWasWrong, setPredictedChurchWasWrong] = useState(false);

  const { control, handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      verifiedChurchGooglePlaceId: { value: '', label: '', address: '' },
    },
  });

  useEffect(() => {
    if (userProfile && userProfile.church) {
      setSearchTerm(userProfile.church + ' church');
    }
  }, [userProfile]);

  useEffect(() => {
    if (churches.length > 0) {
      setValue('verifiedChurchGooglePlaceId', churches[0]);
    }
  }, [churches]);

  useEffect(() => {
    dispatch(hideNav());
    dispatch(getEntityForCurrentUser());
    return () => {
      dispatch(showNav());
      dispatch(reset());
    };
  }, []);

  const handleValidSubmit = handleSubmit(values => {
    const updatedUserProfile: IUserProfile = {
      ...userProfile,
      verifiedChurch: {
        name: values.verifiedChurchGooglePlaceId.label,
        googlePlaceId: values.verifiedChurchGooglePlaceId.value,
      },
    };
    dispatch(updateEntity(updatedUserProfile));
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  // when they've already confirmed their church or the request has just been saved, move on
  if (userProfile !== defaultValue && userProfile !== '' && (!userProfile.church || userProfile.verifiedChurch)) {
    // todo need to find out how to redirect proper
    return <Navigate to="/map" />;
  }

  const predictedChurch = watch('verifiedChurchGooglePlaceId');

  return (
    <Flex direction="column" grow={1}>
      <Box p={[2, 4]}>
        <Box rounded="lg" bg="white" maxW="3xl" width="full" mx="auto" shadow="base" px={[4, 8]} py={4} id="church_confirmation">
          {!isLoading && predictedChurch && (
            <form onSubmit={handleValidSubmit}>
              <VStack spacing={4}>
                <Box id="previous_church_statement">
                  You previously told us your church was <chakra.span fontWeight="semibold">{userProfile.church}</chakra.span>
                </Box>
                {!predictedChurchWasWrong && (
                  <>
                    <Box>Can you confirm that we&apos;ve found the correct church below?</Box>
                    <VStack spacing={0} id="found_church">
                      <Box fontWeight="semibold">{predictedChurch.label}</Box>
                      <Box color={'gray.600'} fontWeight="semibold" fontSize="sm">
                        {predictedChurch.address}
                      </Box>
                    </VStack>
                    <HStack>
                      <Button
                        width="full"
                        size="lg"
                        onClick={() => {
                          setValue('verifiedChurchGooglePlaceId', { value: '', label: '', address: '' });
                          setPredictedChurchWasWrong(true);
                        }}
                      >
                        No
                      </Button>
                      <Button type="submit" width="full" size="lg" className="bg-primary text-white">
                        Yes
                      </Button>
                    </HStack>
                  </>
                )}
                {predictedChurchWasWrong && (
                  <>
                    <Box>Please search for your church below</Box>
                    <ChurchSelector<FormValues> control={control} />
                    <Button type="submit" width="full" size="lg" className="bg-primary text-white">
                      Save
                    </Button>
                  </>
                )}
              </VStack>
            </form>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default FinishChurchSelection;
