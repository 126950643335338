import React, { FunctionComponent } from 'react';

import { Navbar, Nav, NavbarText, NavItem, NavLink, Button } from 'reactstrap';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { BackButton, BackButtonV2 } from '../layout/header/header-components';
import { To } from 'react-router-dom';

// tslint:disable-next-line:ter-arrow-body-style
const ActionNavbar: FunctionComponent<INavbarProps> = props => {
  return (
    <Navbar dark className="bg-dark d-flex">
      <Nav id="header-tabs" navbar className="flex-grow-1 d-flex flex-row justify-content-between align-items-center">
        <BackButton navigateTo={props.navigateBackTo} />
        <span className="navbar-text">{props.title}</span>
        {props.action && (
          <NavItem>
            <NavLink
              id={props.action.id}
              onClick={props.action.onClick}
              style={{ fontSize: '0.9rem' }}
              className="d-flex justify-content-center align-items-center text-light"
            >
              {props.action.icon && <FontAwesomeIcon icon={props.action.icon} size="2x" />}
              {props.action.icon && props.action.label && <span className="ms-1" />}
              {props.action.label}
            </NavLink>
          </NavItem>
        )}
      </Nav>
    </Navbar>
  );
};

export const ActionNavbarV2 = ({ navigateBackTo, title, action }: INavbarProps) => {
  return (
    <Flex as="nav" direction="row" bg="gray.700" color="white" py={4} id="header-tabs" w="full">
      <Flex direction="row" alignItems="center" px={2} w="full">
        <BackButtonV2 navigateTo={navigateBackTo} mx={2} />
        {title && (
          <Text fontWeight="md" fontSize="xl" px={2} mb={0}>
            {title}
          </Text>
        )}
        <Flex grow={1} />
        {action && (
          <Flex>
            <Flex id={action.id} onClick={action.onClick} alignItems="center" justifyContent="center" cursor="pointer">
              {action.icon && <FontAwesomeIcon icon={action.icon} size="xl" />}
              {action.icon && action.label && <span className="ms-1" />}
              <Text fontWeight="md" fontSize="xl" px={2} mb={0}>
                {action.label}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

interface INavbarProps {
  navigateBackTo?: string;
  title?: string;
  action?: INavbarAction;
}

interface INavbarAction {
  onClick: () => void;
  icon?: IconProp;
  label?: string;
  id: string;
  button?: boolean;
}

export default ActionNavbar;
