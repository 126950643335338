import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Box, VStack, Text, Heading, Stack, Badge, useColorModeValue, Collapse, IconButton } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, markAnnouncementAsRead, deleteEntity } from 'app/entities/community-announcement/community-announcement.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { ActionNavbarV2 } from 'app/shared/route/actions-navbar';
import { isPrayerChampionForCurrentEvent } from 'app/shared/auth/event-manager';

dayjs.extend(relativeTime);

export const CommunityAnnouncementList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentPrayerWalkEvent = useCurrentEvent();
  const announcements = useAppSelector(state => state.communityAnnouncement.entities);
  const loading = useAppSelector(state => state.communityAnnouncement.loading);
  const [expandedId, setExpandedId] = React.useState<number | null>(null);
  const account = useAppSelector(state => state.authentication.account);

  useEffect(() => {
    if (currentPrayerWalkEvent) {
      dispatch(getEntities({ prayerWalkEventId: currentPrayerWalkEvent.id }));
    }
  }, [currentPrayerWalkEvent]);

  const handleMarkAsRead = (id: number) => {
    if (currentPrayerWalkEvent) {
      dispatch(markAnnouncementAsRead({ prayerWalkEventId: currentPrayerWalkEvent.id, id }));
    }
  };

  const handleAnnouncementClick = announcement => {
    setExpandedId(expandedId === announcement.id ? null : announcement.id);
    if (!announcement.isRead) {
      handleMarkAsRead(announcement.id);
    }
  };

  const handleEdit = (e: React.MouseEvent, announcementId: number) => {
    e.stopPropagation(); // Prevent announcement expansion when clicking edit
    navigate(`/announcements/${announcementId}/edit`);
  };

  const handleDelete = (e: React.MouseEvent, announcementId: number) => {
    e.stopPropagation();
    navigate(`/announcements/${announcementId}/delete`);
  };

  return (
    <>
      <Heading size="lg" mb={6}>
        Announcements
      </Heading>
      <VStack spacing={4} align="stretch">
        {announcements.map(announcement => (
          <Box
            key={announcement.id}
            p={4}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            bg={useColorModeValue('white', 'gray.700')}
            onClick={() => handleAnnouncementClick(announcement)}
            cursor="pointer"
            transition="all 0.2s"
            _hover={{ shadow: 'lg' }}
          >
            <Stack direction="row" justify="space-between" align="center">
              <Heading size="md">{announcement.title}</Heading>
              <Stack direction="row" spacing={2} align="center">
                {!announcement.isRead && (
                  <Badge colorScheme="blue" display="flex" alignItems="center">
                    <FontAwesomeIcon icon={faCircle} className="me-1" />
                    New
                  </Badge>
                )}
                {announcement.author?.id === account?.id && (
                  <>
                    <IconButton
                      aria-label="Edit announcement"
                      icon={<FontAwesomeIcon icon={faPencil} />}
                      size="sm"
                      variant="ghost"
                      onClick={e => handleEdit(e, announcement.id)}
                    />
                    <IconButton
                      aria-label="Delete announcement"
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      size="sm"
                      variant="ghost"
                      colorScheme="red"
                      onClick={e => handleDelete(e, announcement.id)}
                    />
                  </>
                )}
              </Stack>
            </Stack>
            <Collapse in={expandedId === announcement.id} animateOpacity>
              <Text color={useColorModeValue('gray.600', 'gray.300')} mb={3}>
                {announcement.body}
              </Text>
            </Collapse>
            <Stack direction="row" justify="space-between" align="center" fontSize="sm" color="gray.500">
              <Box>By {announcement.author?.firstName || announcement.author?.login}</Box>
              <Box>{dayjs(announcement.createdDate).fromNow()}</Box>
            </Stack>
          </Box>
        ))}
        {!loading && announcements.length === 0 && (
          <Text color="gray.500" textAlign="center">
            No announcements yet
          </Text>
        )}
      </VStack>
    </>
  );
};

export default CommunityAnnouncementList;
