import React, { useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useAppSelector } from 'app/config/store';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ActionNavbarV2 } from 'app/shared/route/actions-navbar';
import { isPrayerChampionForCurrentEvent } from 'app/shared/auth/event-manager';
import CommunityAnnouncementUpdate from 'app/modules/community-announcement/community-announcement-update';
import CommunityAnnouncementDetail from 'app/modules/community-announcement/community-announcement-detail';
import CommunityAnnouncementDeleteDialog from 'app/modules/community-announcement/community-announcement-delete-dialog';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import CommunityAnnouncementList from 'app/modules/community-announcement/community-announcement-list';

export const CommunityAnnouncementRoutes = () => {
  const navigate = useNavigate();
  const isPrayerChampion = useAppSelector(state => isPrayerChampionForCurrentEvent(state.authentication, state.currentPrayerWalkEvent));

  return (
    <div className="flex-grow-1">
      <ActionNavbarV2
        navigateBackTo="/account"
        title={`Account`}
        action={
          isPrayerChampion && {
            id: ' create-announcement',
            icon: faPlus,
            label: 'Create',
            onClick: () => navigate('new'),
          }
        }
      />
      <Box p={[2, 4]}>
        <Box rounded="lg" bg="white" maxW="3xl" width="full" mx="auto" shadow="base" px={[4, 8]} py={4}>
          <ErrorBoundaryRoutes>
            <Route path="/" element={<CommunityAnnouncementList />} />
            <Route path="new" element={<CommunityAnnouncementUpdate />} />
            <Route path=":id">
              <Route index element={<CommunityAnnouncementDetail />} />
              <Route path="edit" element={<CommunityAnnouncementUpdate />} />
              <Route path="delete" element={<CommunityAnnouncementDeleteDialog />} />
            </Route>
          </ErrorBoundaryRoutes>
        </Box>
      </Box>
    </div>
  );
};

export default CommunityAnnouncementRoutes;
