import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getSession } from 'app/shared/reducers/authentication';
import { AppThunk } from 'app/config/store';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  updateSuccess: false,
  updateFailure: false,
  faviconFolderUrl: null,
};

export type PrayerWalkEventFaviconState = Readonly<typeof initialState>;

// Actions
const apiUrl = (id: number) => `api/prayer-walk-events/${id}/favicon`;

export const uploadPrayerWalkEventFavicon = createAsyncThunk(
  'prayerWalkEventFavicon/upload',
  async ({ formData, id }: { formData: FormData; id: number }, thunkAPI) => {
    const response = await axios.post<any>(apiUrl(id), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const PrayerWalkEventFaviconSlice = createSlice({
  name: 'prayerWalkEventFavicon',
  initialState: initialState as PrayerWalkEventFaviconState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(uploadPrayerWalkEventFavicon.pending, state => {
        state.loading = true;
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updateFailure = false;
      })
      .addCase(uploadPrayerWalkEventFavicon.rejected, (state, action) => {
        state.loading = false;
        state.updateSuccess = false;
        state.errorMessage = (action.error as any).response.data.title;
        state.updateFailure = true;
      })
      .addCase(uploadPrayerWalkEventFavicon.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
        state.updateFailure = false;
        state.successMessage = 'Favicon uploaded!';
        state.faviconFolderUrl = action.payload.data.faviconFolderUrl;
      });
  },
});

export const { reset } = PrayerWalkEventFaviconSlice.actions;

// Reducer
export default PrayerWalkEventFaviconSlice.reducer;
