import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { IUserProfile } from 'app/shared/model/user-profile.model';
import { handleRegister, reset } from 'app/modules/account/register/register.reducer';
import { RegistrationSuccessPage } from 'app/modules/account/register/registration-success';
import { FormCheckbox, FormField, FormPassword } from 'app/shared/components/form-field';
import { useEffect, useState } from 'react';
import React from 'react';
import { isEmail } from 'react-jhipster';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, chakra, HStack, Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ChurchOption, ChurchSelector } from 'app/shared/components/church-selector';

interface FormValues {
  login: string;
  email: string;
  church: string;
  verifiedChurchGooglePlaceId: ChurchOption;
  firstName: string;
  lastName: string;
  firstPassword: string;
  secondPassword: string;
  privacyAgreement: boolean;
  consentToContactAboutPrayerWalkEvent: boolean;
  consentToContactFromOrganisation: boolean;
  consentToProvidingAnonymousAnalytics: boolean;
  publiclyViewable: boolean;
}

export const RegisterForm = () => {
  const dispatch = useAppDispatch();
  const prayerWalkEvent = useCurrentEvent();
  const navigate = useNavigate();

  const emailUsed = useAppSelector(state => state.register.emailUsedToSignUp);
  const registrationSuccess = useAppSelector(state => state.register.registrationSuccess);
  const registerError = useAppSelector(state => state.register.errorMessage);
  const registerErrorType = useAppSelector(state => state.register.errorType);
  const loading = useAppSelector(state => state.register.loading);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      login: '',
      email: '',
      church: '',
      verifiedChurchGooglePlaceId: { value: '', label: '', address: '' },
      firstName: '',
      lastName: '',
      firstPassword: '',
      secondPassword: '',
      privacyAgreement: false,
      consentToContactAboutPrayerWalkEvent: true,
      consentToContactFromOrganisation: false,
      consentToProvidingAnonymousAnalytics: false,
      publiclyViewable: true,
    },
  });

  const handleValidSubmit = handleSubmit(values => {
    const verifiedChurch = values.verifiedChurchGooglePlaceId.label
      ? {
          name: values.verifiedChurchGooglePlaceId.label,
          googlePlaceId: values.verifiedChurchGooglePlaceId.value,
        }
      : null;
    const userProfile: IUserProfile = {
      church: values.church,
      consentToContactAboutPrayerWalkEvent: values.consentToContactAboutPrayerWalkEvent,
      consentToContactFromOrganisation: values.consentToContactFromOrganisation,
      consentToProvidingAnonymousAnalytics: values.consentToProvidingAnonymousAnalytics,
      publiclyViewable: values.publiclyViewable,
      verifiedChurch,
    };
    dispatch(
      handleRegister({
        login: values.login,
        email: values.email,
        password: values.firstPassword,
        firstName: values.firstName,
        lastName: values.lastName,
        userProfile,
        prayerWalkEventId: prayerWalkEvent.id,
        langKey: 'en',
      })
    );
  });

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );

  useEffect(() => {
    if (registrationSuccess) {
      navigate('/registration-success', { state: { emailUsed } });
    }
  }, [registrationSuccess, navigate, emailUsed]);

  const password = watch('firstPassword');

  return (
    <chakra.form onSubmit={handleValidSubmit} noValidate>
      <Stack spacing="8">
        <FormField
          label="Username"
          errors={errors}
          placeholder="Your username"
          isRequired={true}
          {...register('login', {
            required: { value: true, message: 'Your username is required.' },
            pattern: {
              value: /^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$/, // email address or username
              message:
                "Your username can be your email address (if you're ok for that to be publicly viewable) or any combination of letters, numbers, - _ . or @. Your username should not contain any spaces.",
            },
            minLength: { value: 1, message: 'Your username is required to be at least 1 character.' },
            maxLength: { value: 50, message: 'Your username cannot be longer than 50 characters.' },
          })}
          helperText="This will be publicly visible"
          data-cy="username"
        />
        <FormField
          label="Email"
          errors={errors}
          isRequired={true}
          placeholder="Your email"
          {...register('email', {
            required: { value: true, message: 'Your email is required.' },
            minLength: { value: 5, message: 'Your email is required to be at least 5 characters.' },
            maxLength: { value: 254, message: 'Your email cannot be longer than 50 characters.' },
            validate: v => isEmail(v) || 'Your email is invalid.',
          })}
          type="email"
          data-cy="email"
        />

        <FormField
          label="First name"
          errors={errors}
          isRequired={true}
          placeholder="Your first name"
          {...register('firstName', {
            required: { value: true, message: 'Your first name is required.' },
            maxLength: { value: 50, message: 'Your first name cannot be longer than 50 characters.' },
          })}
          data-cy="firstName"
        />

        <FormField
          label="Last name"
          errors={errors}
          isRequired={false}
          placeholder="Your last name"
          {...register('lastName', {
            maxLength: {
              value: 50,
              message: 'Your last name cannot be longer than 50 characters.',
            },
          })}
          data-cy="lastName"
        />

        <ChurchSelector<FormValues> control={control} />

        <FormPassword
          label="Password"
          errors={errors}
          isRequired={true}
          placeholder="password"
          {...register('firstPassword', {
            required: { value: true, message: 'Your password is required.' },
            minLength: { value: 4, message: 'Your password is required to be at least 4 characters.' },
            maxLength: { value: 50, message: 'Your password cannot be longer than 50 characters.' },
          })}
          data-cy="password"
        />

        <FormPassword
          label="New password confirmation"
          errors={errors}
          isRequired={true}
          placeholder="Confirm the new password"
          {...register('secondPassword', {
            required: { value: true, message: 'Your confirmation password is required.' },
            minLength: { value: 4, message: 'Your confirmation password is required to be at least 4 characters.' },
            maxLength: { value: 50, message: 'Your confirmation password cannot be longer than 50 characters.' },
            validate: v => v === password || 'The password and its confirmation do not match!',
          })}
          data-cy="password"
        />

        <FormCheckbox
          errors={errors}
          {...register('privacyAgreement', {
            required: { value: true, message: 'You must agree to the privacy policy.' },
          })}
        >
          I agree to the{' '}
          <a id="privacyPolicyLink" target="_blank" rel="noreferrer" href="https://prayerwalk.app/privacy-policy" style={{ zIndex: 10 }}>
            privacy policy
          </a>{' '}
          and the{' '}
          <a id="termsOfService" target="_blank" rel="noreferrer" href="https://prayerwalk.app/terms-of-service" style={{ zIndex: 10 }}>
            terms of service
          </a>
        </FormCheckbox>

        <FormCheckbox errors={errors} {...register('consentToContactAboutPrayerWalkEvent')}>
          Please keep me informed via email about {prayerWalkEvent.name} news and updates
        </FormCheckbox>
        <FormCheckbox errors={errors} {...register('consentToContactFromOrganisation')}>
          I would like the organisers to let me know about similar future events so I don&apos;t miss out
        </FormCheckbox>
        <FormCheckbox errors={errors} {...register('consentToProvidingAnonymousAnalytics')}>
          I am happy to make this app better by providing anonymous analytics
        </FormCheckbox>
        <FormCheckbox errors={errors} {...register('publiclyViewable')}>
          I am happy for my profile to be publicly viewable
        </FormCheckbox>
        {registerError && (
          <Alert status="error" variant="subtle" id="register-error">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Registration failed!</AlertTitle>
              <AlertDescription>
                {registerErrorType === 'https://www.jhipster.tech/problem/email-already-used' ? (
                  <>
                    You already have an account with that email, <Link to="/">please login</Link>
                  </>
                ) : (
                  registerError
                )}
              </AlertDescription>
            </Box>
          </Alert>
        )}
        <HStack>
          <Button id="cancel" size="lg" type="button" as={Link} to={'/'}>
            Cancel
          </Button>
          <Button flexGrow={1} data-cy="submit" type="submit" colorScheme="primary" size="lg" isLoading={loading}>
            Register
          </Button>
        </HStack>
      </Stack>
    </chakra.form>
  );
};

export const RegistrationSuccess = () => {
  const location = useLocation();
  const state = location.state as { emailUsed: string };
  if (state) {
    return <RegistrationSuccessPage emailUsed={state.emailUsed} />;
  } else {
    return <RegistrationSuccessPage />;
  }
};
