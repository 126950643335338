import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ICommunityAnnouncement, defaultValue } from 'app/shared/model/community-announcement.model';

const initialState: EntityState<ICommunityAnnouncement> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = (prayerWalkEventId: number) => `api/prayer-walk-events/${prayerWalkEventId}/community-announcements`;

// Actions

export const getEntities = createAsyncThunk(
  'communityAnnouncement/fetch_entity_list',
  async ({ page, size = 1000, sort = 'id,desc', prayerWalkEventId }: IQueryParams & { prayerWalkEventId: number }) => {
    const requestUrl = `${apiUrl(prayerWalkEventId)}?${
      sort ? `page=${page}&size=${size}&sort=${sort}&` : ''
    }cacheBuster=${new Date().getTime()}`;
    return axios.get<ICommunityAnnouncement[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'communityAnnouncement/fetch_entity',
  async ({ id, prayerWalkEventId }: { prayerWalkEventId: number; id: string | number }) => {
    const requestUrl = `${apiUrl(prayerWalkEventId)}/${id}`;
    return axios.get<ICommunityAnnouncement>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'communityAnnouncement/create_entity',
  async ({ entity, prayerWalkEventId }: { entity: ICommunityAnnouncement; prayerWalkEventId: number }, thunkAPI) => {
    const result = await axios.post<ICommunityAnnouncement>(apiUrl(prayerWalkEventId), cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'communityAnnouncement/update_entity',
  async ({ entity, prayerWalkEventId }: { entity: ICommunityAnnouncement; prayerWalkEventId: number }, thunkAPI) => {
    const result = await axios.put<ICommunityAnnouncement>(`${apiUrl(prayerWalkEventId)}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'communityAnnouncement/partial_update_entity',
  async ({ entity, prayerWalkEventId }: { entity: ICommunityAnnouncement; prayerWalkEventId: number }, thunkAPI) => {
    const result = await axios.patch<ICommunityAnnouncement>(`${apiUrl(prayerWalkEventId)}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'communityAnnouncement/delete_entity',
  async ({ id, prayerWalkEventId }: { prayerWalkEventId: number; id: number }) => {
    const requestUrl = `${apiUrl(prayerWalkEventId)}/${id}`;
    const result = await axios.delete<ICommunityAnnouncement>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const markAnnouncementAsRead = createAsyncThunk(
  'communityAnnouncement/mark_as_read',
  async ({ prayerWalkEventId, id }: { prayerWalkEventId: number; id: string | number }, thunkAPI) => {
    const result = await axios.post<void>(`${apiUrl(prayerWalkEventId)}/${id}/read`);
    thunkAPI.dispatch(getEntities({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const CommunityAnnouncementSlice = createEntitySlice({
  name: 'communityAnnouncement',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(markAnnouncementAsRead.fulfilled, state => {
        state.loading = false;
      })
      .addCase(markAnnouncementAsRead.pending, state => {
        state.loading = true;
      })
      .addCase(markAnnouncementAsRead.rejected, state => {
        state.loading = false;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = CommunityAnnouncementSlice.actions;

// Reducer
export default CommunityAnnouncementSlice.reducer;
