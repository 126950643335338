import React, { useEffect } from 'react';
import { Route, Navigate } from 'react-router-dom';
import Loadable from 'react-loadable';

import FinishOauth2Registration from 'app/modules/account/register/finish-oauth2-registration';
import SelectPrayerWalkEvent from 'app/modules/prayer-walk-event/select-prayer-walk-event';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import EventAwareHowToUsePage from 'app/modules/prayer-walk-event/event-aware-how-to';
import PrivateRoute from 'app/shared/auth/private-route-2';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { logPageView } from 'app/shared/util/analytics-tracker';
import OAuth2Callback from 'app/modules/login/oauth2-callback';
import DataDeletionInstructions from 'app/modules/privacy-policy/data-deletion-instructions';
import { EveryoneProgressPage, MyselfProgressPage, ProgressPage } from 'app/modules/participants/progress-page';
import { PrayerWalkingRouter } from 'app/shared/route/PrayerWalkingRouter';
import { MapHome } from 'app/entities/map/map-home';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import { PrayerNeedsPage } from 'app/modules/prayer-needs/prayer-needs-page';
import FinishChurchSelection from 'app/modules/account/register/finish-church-selection';
import AccountChecks from 'app/modules/account/account-checks';
import CommunityAnnouncementRoutes from './modules/community-announcement/community-announcement-routes';

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account/routes'),
  loading: () => <LoadingSpinner />,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <LoadingSpinner />,
});

const AppRoutes = () => {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   logPageView(history);
  // }, [history]);

  return (
    <ErrorBoundaryRoutes>
      <Route path="/data-deletion-instructions" element={<DataDeletionInstructions />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/oauth/callback" element={<OAuth2Callback />} />
      <Route
        path="/admin/*"
        element={
          <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
            <Admin />
          </PrivateRoute>
        }
      />
      <Route
        path="/account/oauth2-register"
        element={
          <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
            <FinishOauth2Registration />
          </PrivateRoute>
        }
      />
      <Route
        path="/account/confirm-church"
        element={
          <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
            <FinishChurchSelection />
          </PrivateRoute>
        }
      />
      <Route
        path="/change-event"
        element={
          <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
            <SelectPrayerWalkEvent />
          </PrivateRoute>
        }
      />
      <Route
        path="/account/*"
        element={
          <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
            <Account />
          </PrivateRoute>
        }
      />
      <Route
        path="/how-to-use"
        element={
          <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
            <EventAwareHowToUsePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/progress"
        element={
          <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
            {/* needs outlet */}
            <ProgressPage />
          </PrivateRoute>
        }
      >
        <Route path="everyone" element={<EveryoneProgressPage />} />
        <Route path="myself" element={<MyselfProgressPage />} />
      </Route>
      <Route
        path="/prayer-needs"
        element={
          <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
            <PrayerNeedsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/map/*"
        element={
          <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
            <AccountChecks>
              <MapHome />
            </AccountChecks>
          </PrivateRoute>
        }
      />
      <Route // for old links to still work
        path="/prayer-route"
        element={<Navigate to="/map" />}
      />
      <Route
        path="/announcements/*"
        element={
          <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
            <CommunityAnnouncementRoutes />
          </PrivateRoute>
        }
      />
      <Route path="/*" element={<Home />} />
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export const PrayerWalkEventAgnosticRoutes = () => {
  return (
    <PrayerWalkingRouter>
      <ErrorBoundaryRoutes>
        <Route path="/data-deletion-instructions" element={<DataDeletionInstructions />} />
      </ErrorBoundaryRoutes>
    </PrayerWalkingRouter>
  );
};

export default AppRoutes;
