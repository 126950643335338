import React from 'react';
import { hasResourceAuthority, IResourceAuthority } from 'app/shared/auth/private-route-per-resource';
import { CurrentPrayerWalkEventState } from 'app/modules/prayer-walk-event/current-prayer-walk-event.reducer';
import { AuthenticationState } from 'app/shared/reducers/authentication';
import { AUTHORITIES } from 'app/config/constants';

export const eventManagerPermissionForCurrentEvent = (currentPrayerWalkEvent: CurrentPrayerWalkEventState): IResourceAuthority[] => {
  return [
    { resourceType: 'prayerWalkEvent', resourceId: currentPrayerWalkEvent.currentPrayerWalkEvent.id, authorityName: 'ROLE_EVENT_MANAGER' },
  ];
};

export const prayerChampionPermissionForCurrentEvent = (currentPrayerWalkEvent: CurrentPrayerWalkEventState): IResourceAuthority[] => {
  return [
    {
      resourceType: 'prayerWalkEvent',
      resourceId: currentPrayerWalkEvent.currentPrayerWalkEvent.id,
      authorityName: AUTHORITIES.PRAYER_CHAMPION,
    },
  ];
};

export const isEventManagerForCurrentEvent = (
  authentication: AuthenticationState,
  currentPrayerWalkEvent: CurrentPrayerWalkEventState
): boolean => {
  return hasResourceAuthority(authentication.account.resourceAuthorities, eventManagerPermissionForCurrentEvent(currentPrayerWalkEvent));
};

export const isPrayerChampionForCurrentEvent = (
  authentication: AuthenticationState,
  currentPrayerWalkEvent: CurrentPrayerWalkEventState
): boolean => {
  return hasResourceAuthority(authentication.account.resourceAuthorities, prayerChampionPermissionForCurrentEvent(currentPrayerWalkEvent));
};
