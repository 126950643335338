import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, HStack, Alert } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from 'app/entities/community-announcement/community-announcement.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';

export const AnnouncementBanner = () => {
  const dispatch = useAppDispatch();
  const currentPrayerWalkEvent = useCurrentEvent();
  const announcements = useAppSelector(state => state.communityAnnouncement.entities);

  useEffect(() => {
    if (currentPrayerWalkEvent) {
      dispatch(getEntities({ prayerWalkEventId: currentPrayerWalkEvent.id }));
    }
  }, [currentPrayerWalkEvent]);

  const latestUnread = announcements.find(a => !a.isRead);

  if (!latestUnread || !currentPrayerWalkEvent) return null;

  return (
    <Alert color="info">
      <HStack spacing={3} mx="auto" alignItems="center" justifyContent="center">
        <FontAwesomeIcon icon={faBullhorn} />
        <Box fontSize="sm">{latestUnread.title}</Box>
        <Link as={RouterLink} to="/announcements" color="blue.500" fontWeight="medium" fontSize="sm">
          Read more
        </Link>
      </HStack>
    </Alert>
  );
};

export default AnnouncementBanner;
