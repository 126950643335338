import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import authentication from './authentication';
import applicationProfile from './application-profile';
import appLayout from './app-layout';

import administration from 'app/modules/administration/administration.reducer';
import userManagement from 'app/modules/administration/user-management/user-management.reducer';
import register from 'app/modules/account/register/register.reducer';
import activate from 'app/modules/account/activate/activate.reducer';
import password from 'app/modules/account/password/password.reducer';
import settings from 'app/modules/account/settings/settings.reducer';
import passwordReset from 'app/modules/account/password-reset/password-reset.reducer';
import participants from 'app/modules/participants/participants.reducer';
// prettier-ignore
import prayerRoute from 'app/entities/prayer-route/prayer-route.reducer';
// prettier-ignore
import userProfile from 'app/entities/user-profile/user-profile.reducer';
// prettier-ignore
import stats from 'app/modules/participants/stats/stats.reducer';

// prettier-ignore
import prayerWalkEvent from 'app/entities/prayer-walk-event/prayer-walk-event.reducer';
import currentPrayerWalkEvent from 'app/modules/prayer-walk-event/current-prayer-walk-event.reducer';
import mapState from 'app/modules/map/map-state.reducer';
import mapLegendState from 'app/shared/components/map-legend.reducer';
import profilePictureState from 'app/modules/account/settings/profile-picture.reducer';
// prettier-ignore
import userAuthorityResource from 'app/entities/user-authority-resource/user-authority-resource.reducer';
import portalSession from 'app/modules/organisation/reducers/portal-session.reducer';
import availableSubdomain from 'app/modules/organisation/reducers/available-subdomain.reducer';
import prayerWalkEventLogo from 'app/modules/organisation/reducers/prayer-walk-event-logo.reducer';
import prayerWalkEventFavicon from 'app/modules/organisation/reducers/prayer-walk-event-favicon.reducer';
import prayerWalkEventDashboardStats from 'app/modules/organisation/main/prayer-walk-event-stats.reducer';
import prayerEventChallengeStats from 'app/modules/organisation/main/prayer-event-challenge-stats.reducer';
// prettier-ignore
import prayerNeed from 'app/entities/prayer-need/prayer-need.reducer';
import organisation from 'app/entities/organisation/organisation.reducer';
import paymentSubscription from 'app/entities/payment-subscription/payment-subscription.reducer';
import church from 'app/entities/church/church.reducer';
import usersOrganisation from 'app/modules/organisation/reducers/users-organisation.reducer';
import mailingList from 'app/modules/organisation/reducers/mailing-list.reducer';
import checkoutSession from 'app/modules/organisation/reducers/checkout-session.reducer';
import prayerEventChallenge from 'app/entities/prayer-event-challenge/prayer-event-challenge.reducer';
import userStats from 'app/modules/participants/stats/user-stats.reducer';
import prayerNeedTable from 'app/modules/organisation/reducers/prayer-need-table.reducer';
import createUserAndInviteToOrganisation from 'app/modules/organisation/reducers/create-user-and-invite-organisation.reducer';
import userEngagementStats from 'app/modules/organisation/reducers/user-engagement-stats.reducer';
import communityAnnouncement from 'app/entities/community-announcement/community-announcement.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const rootReducer = {
  authentication,
  applicationProfile,
  appLayout,
  administration,
  userManagement,
  register,
  activate,
  participants,
  passwordReset,
  password,
  settings,
  prayerRoute,
  userProfile,
  stats,
  prayerWalkEvent,
  currentPrayerWalkEvent,
  mapState,
  mapLegendState,
  userAuthorityResource,
  profilePictureState,
  prayerNeed,
  paymentSubscription,
  organisation,
  usersOrganisation,
  portalSession,
  availableSubdomain,
  prayerWalkEventDashboardStats,
  prayerEventChallengeStats,
  prayerWalkEventLogo,
  prayerWalkEventFavicon,
  mailingList,
  checkoutSession,
  prayerEventChallenge,
  userStats,
  prayerNeedTable,
  createUserAndInviteToOrganisation,
  userEngagementStats,
  church,
  communityAnnouncement,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
};

export default rootReducer;
