import React from 'react';
import { useAppSelector } from 'app/config/store';
import { Image, ImageProps } from '@chakra-ui/react';
import { useEventFavicons } from './hooks/use-event-favicon';

export interface IInstallLogoProps {
  logoUrl: string;
  name: string;
}

export const InstallLogo = (props: IInstallLogoProps & ImageProps) => (
  <Image mx="auto" my={2} bg="white" width="100px" src={props.logoUrl} alt={`${props.name} Icon`} />
);

export const EventAwareInstallLogo = () => {
  const prayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);
  const { getFaviconUrl } = useEventFavicons();
  return <InstallLogo logoUrl={getFaviconUrl()} name={prayerWalkEvent.name} />;
};

export default EventAwareInstallLogo;
