import { useAppSelector } from 'app/config/store';

export const useEventFavicons = () => {
  const prayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);

  const getFaviconUrl = () => {
    const baseUrl = prayerWalkEvent.settings.logos.faviconFolderUrl;
    // Handle both old and new folder structures
    return baseUrl.includes('%2Ffavicons') ? `${baseUrl}%2Fandroid-chrome-512x512.png?alt=media` : `${baseUrl}%2Ffavicon.svg?alt=media`;
  };

  return {
    getFaviconUrl,
  };
};
