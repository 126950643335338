import React, { useMemo, useState } from 'react';
import { Box, FormControl, FormLabel, VStack } from '@chakra-ui/react';
import { Controller, Path } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { AsyncSelect, chakraComponents, GroupBase, OptionBase, SelectComponentsConfig } from 'chakra-react-select';
import _debounce from 'lodash.debounce';
import { useSearchChurches } from 'app/shared/hooks/useSearchChurches';
import { IChurch } from 'app/shared/model/church.model';

export interface ChurchOption extends OptionBase {
  label: string;
  value: string;
  address: string;
}

export type IFieldValuesWithVerifiedChurch = {
  verifiedChurchGooglePlaceId: ChurchOption;
};

export type IChurchSelectorProps<FieldValues extends IFieldValuesWithVerifiedChurch> = {
  control: FieldValues extends IFieldValuesWithVerifiedChurch ? Control<FieldValues> : any;
  defaultValue?: IChurch;
};

export const ChurchSelector = <FieldValues extends IFieldValuesWithVerifiedChurch>({
  control,
  defaultValue,
}: IChurchSelectorProps<FieldValues>) => {
  const [loadedOptions, setLoadedOptions] = useState<ChurchOption[]>([]);
  const { searchChurches } = useSearchChurches();

  const customComponents: SelectComponentsConfig<ChurchOption, false, GroupBase<ChurchOption>> = {
    Option: ({ children, ...props }) => (
      <chakraComponents.Option {...props}>
        <VStack spacing={2} align="flex-start">
          <Box>{props.data.label}</Box>
          <Box color={'gray.600'} fontWeight="medium" fontSize="sm">
            {props.data.address}
          </Box>
        </VStack>
      </chakraComponents.Option>
    ),
  };

  const defaultValueOption: ChurchOption | undefined = useMemo(() => {
    if (defaultValue) {
      return { label: defaultValue.name, value: defaultValue.googlePlaceId, address: '' };
    } else {
      return undefined;
    }
  }, [defaultValue]);

  return (
    <Controller<FieldValues>
      control={control}
      name={'verifiedChurchGooglePlaceId' as Path<FieldValues>}
      render={({ field: { onChange, onBlur, value, name, ref } }) => {
        return (
          <FormControl>
            <FormLabel htmlFor="verifiedChurchGooglePlaceId">Your church</FormLabel>
            <AsyncSelect<ChurchOption, false, GroupBase<ChurchOption>>
              id="verifiedChurchGooglePlaceId"
              size="lg"
              name={name}
              ref={ref}
              onChange={onChange}
              onBlur={onBlur}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value={value}
              defaultValue={defaultValueOption}
              noOptionsMessage={() => 'Starting typing to search for your church'}
              placeholder="Type the name of your church..."
              defaultOptions={loadedOptions}
              loadOptions={_debounce((userInput, callback) => {
                searchChurches(userInput).then(options => {
                  callback(options);
                  setLoadedOptions(options);
                });
              }, 1000)}
              components={customComponents}
            />
          </FormControl>
        );
      }}
    />
  );
};
